<template>
  <div>
    <a href="https://muazamkamal.com"
      ><img id="nini-chibi" alt="Chibi logo" src="@/assets/chibi.png"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'ChibiComp',
}
</script>

<style scoped>
#nini-chibi {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */

  display: block;
  margin: 0 auto;
}
</style>
